import './App.css';
import Home from './Pages/Home'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

function App() {

  
  const firebaseConfig = {
    apiKey: "AIzaSyBqhnezdVcLXzRPnIdUUMBpgdX0en81B2A",
    authDomain: "steaksoft-website-51bc9.firebaseapp.com",
    projectId: "steaksoft-website-51bc9",
    storageBucket: "steaksoft-website-51bc9.appspot.com",
    messagingSenderId: "375815365688",
    appId: "1:375815365688:web:7fbb076d8deade7d5b72cd",
    measurementId: "G-KPFK2ZM08R"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

  return (
    <div>
      <Home/>
    </div>
  );
}

export default App;
