import React from 'react'
import './ProjectCard.css'
import { FaGooglePlay, FaWindows } from 'react-icons/fa'
import { IconContext } from 'react-icons'
import { ExternalLink } from 'react-external-link'

function ProjectCard(props) {
    return (
        <div className='projectcard'>
            <ExternalLink href={props.link}>
                <div className='projectcard-img'>
                    <img src={props.img} width={'100%'} alt='game-icon'></img>
                </div>
            </ExternalLink>
            <div className='projectcard-text'>
                <h1 className='projectcard-title'>{props.title}</h1>
                <h1 className='projectcard-desc'>{props.desc}</h1>
            </div>
            <div className='projectcard-platform'>
                <IconContext.Provider value={{ className: "projectcard-platform-icon", size: '20px' }}>
                    {props.gplay && <FaGooglePlay />}
                    {props.win && <FaWindows />}
                </IconContext.Provider>
            </div>

        </div>
    )
}

export default ProjectCard